/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0px;
  text-align: center;
}

header {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    justify-content: center;
    
  }

  align-items: center;
  padding: 16px;
  background-color: #333;
  color: #fff;
}

h2 {
  color: #333;
  font-size: 1.4em;
}

/* For mobile devices */
@media (max-width: 600px) {
  h2 {
    font-size: 1.25em; /* adjust as needed */
  }
}

nav a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
}

.icon {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  margin-right: 10px;
}

/* Parallax Styles */
.parallax-container {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.parallax-container .parallax-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Slider Styles */
.slick-slider {
  margin: 40px 0;
}

.slick-slide {
  padding: 20px;
  text-align: center;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #333;
}

.slick-dots li.slick-active button:before {
  color: #666;
}

/* Button Styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  font-size: 19px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: green;
}

.btn:hover {
  background-color: darkgreen;
}

/* Other Section Styles */
section {
  padding: 40px 20px;
}


section#deck {
  padding: 0;
}

section#home {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

section h2 {
  text-align: center;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.feature-images {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center
}

.feature-images img {
  width: 30%;
  height: auto;
}

/* For mobile devices */
@media (max-width: 600px) {
  .feature-images img {
    width: 55%;
  }
}

.feature-text {
  flex: 1;
  text-align: center;
}

.feature-text-deck {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 32px;
  margin-top: 16px;
}

.feature-text-deck h2 {
  color: white;
  margin: 0;
  margin-bottom: 16px;
}

.download-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 100%; /* Ensure the wrapper takes up the full width of its parent */
}

.download-buttons-wrapper img {
  max-width: 150px;
  height: auto;
  margin-right: 16px;
  margin-left: 16px;
}

.companion_wrapper {
  /* background-color: #333; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
}

.logo-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.9);  
  align-items: center;
}

@keyframes animateBackground {
  0% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.jesus-creation {
  background-repeat: no-repeat;
}

footer {
  background-color: #171717;
}

.jesus-ascend-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
}

/* For mobile devices */
@media (max-width: 600px) {
  .jesus-ascend-wrapper {
    width: 100%;
    height: 235px;
  }
}

.jesus-ascend-bumper {
  height: 100%;
  width: 100%;
  background-color: #171717;
}

@media (max-width: 600px) {
  .jesus-ascend-bumper {
    width: 0%;
  }

  .jesus-ascend-bottom-bumper {
    width: 100%;
    background-color: #171717;
    height: 15px;
    z-index: 1;
    bottom: 0;
    position: absolute;
  }

  .jesus-ascend-top-bumper {
    width: 100%;
    background-color: #171717;
    height: 25px;
    z-index: 1;
    position: absolute;
  }
}

.jesus-image {
  width: 65%;
  height: 65%;
  margin-top: -50px;
}

/* For mobile devices */
@media (max-width: 600px) {
  .jesus-image {
    width: 100%;
    height: 100%;
    margin-top: -20px;
  }
}

.large-linear-logo {
  width: 25%;
  height: 25%;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* For mobile devices */
@media (max-width: 600px) {
  .large-linear-logo {
    width: 85%;
    height: 85%;
  }
}

.home-section {
  height: 500px;
}

/* For mobile devices */
@media (max-width: 600px) {
  .home-section {
    height: 400px;
  }
}

.carousel-image1 {
  width: 60% !important;
}

.carousel-image2 {
  width: 39% !important;
}

.carousel-image3 {
  width: 60% !important;
}

/* For mobile devices */
@media (max-width: 600px) {
  .carousel-image1 {
    width: 95% !important;
  }

  .carousel-image2 {
    width: 70% !important;
  }

  .carousel-image3 {
    width: 95% !important;
    margin-top: 40px !important;
  }
}

.deck-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  margin-top: 0px;
}

.logo-deck {
  width: 30%;
  /* margin-bottom: 16px; */
}

/* For mobile devices */
@media (max-width: 600px) {
  .logo-deck {
    width: 80%;
  }
}

.deck-logo-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 14px;
  /* border-bottom: 1px solid #777777;
  border-top: 1px solid #777777; */
}

.now-available-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  padding-right: 3%;
  padding-left: 3%;
  border-radius: 35px;
  height: 10px;
  background-color: cadetblue;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 16px;
}

.kh-about-wrapper {
  width: 60%;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kh-about-wrapper p {
  font-size: 1.2em;
  line-height: 1.5em;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

footer button, footer a {
  color: white;
  text-decoration: none;
  font-size: 11px;
  margin-right: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

footer button:hover {
  text-decoration: underline;
}